table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 75%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }

  @media only screen and (max-width: 700px) {
    table {
      width: 100%;
    }
  }